body {
  /* background-image: url("./img/bg.png"); */
  background-repeat: no-repeat;
  background-position: top center;
}

p, li, h2, h1 {
  color: #707070;
}

.center {
  text-align: center;
}

.spacer-15 {
  height: 15px;
}

.spacer-30 {
  height: 30px;
}

.spacer-60 {
  height: 60px;
}

.form {
  border: 1px solid #dfdfdf;
  border-radius: 26px;
  background-color: #ffffff78;
  padding: 15px 30px 30px;
  margin: 0 15px;
}

.wrap {
  border: 1px solid #dfdfdf;
  border-radius: 26px;
  background-color: #ffffff78;
  padding: 5px 15px 15px;
}

.wrap p{
  line-height: 20px;
}

.lang{
  list-style:none;
  text-align: right;
  margin-bottom: 30px;
}

.lang li{
  display: inline;
  padding: 5px;  
}

.langactive{
  color:black;
  border-bottom: 1px solid black;
}

.input {
  margin-top: 10px !important;
}

.garis {
  width: 150px;
  margin: 0 auto;
  height: 1.5px;
  background-color: #707070;
}

.MuiInputBase-input {
  border-bottom-color: #707070 !important;
}

.form-otentikasi {
  padding: 20vh 30px;
}

.button {
  border-radius: 15px !important;
  box-shadow: 3px 3px 4px #0011ff99;
}

.link {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.linkkbtn {
  font-size: 12px !important;
  border-radius: 15px !important;
  box-shadow: 3px 3px 4px #0011ff99 !important;
  text-transform: lowercase !important;
  padding: 5px 3px !important;
  text-align: left !important;
}

.button-secondary {
  box-shadow: 3px 3px 4px #ff3d0099 !important;
}

.tips {
  color: #0011ff99;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 7px;
}

.MuiPaper-root {
  background-color: #0011ff33 !important;
  color: #ff5b5b !important;
}

.MuiTab-root {
  font-size: 12px !important;
  color: #ff5b5b !important;
  font-family: "Roboto-Bold" !important;
}

.MuiTabs-indicator {
  background-color: #ffffff !important;
}

.preview {
  color: #383b67 !important;
}

.btn-sm {
  font-size: 12px !important;
  text-transform: capitalize !important;
}

.btn-1{
  border-radius: 30px!important
}

.input1 {
  width: 100%;
  background: transparent;
  border: none !important;
  border-bottom: 2px solid #dfdfdf !important;
  color: #707070;
  padding: 10px 0px;
  margin-bottom: 8px;
}

.inputsuccess{
  border-bottom: 2px solid #383b67bf !important;
  color:#383b67;
}

.form1 {
  padding: 10px 20px 15px;
}

:focus {
  outline: none !important;
}

.triangle {
  width: 0;
  height: 0;

  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 15px solid #ffffffff;
  position: absolute;
  left: 50%;
  margin-top: 2px;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9;
}

.triangle1 {
  border-bottom: 15px solid #fef9e5;
}

.triangle2 {
  width: 0;
  height: 0;

  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 15px solid #dfdfdf;
  position: absolute;
  left: 50%;
  margin-top: 0px;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pro {
  color: #ff5b5b !important;
}

.btn-disabled {
  color: #a5a5a5 !important;
}

.h-line {
  text-align: center;
  position: relative;
  font-size: 16px;
  display: block;
  color: #707070;
}

.h-line > * {
  display: inline-block;
  position: relative;
}

.h-line span::before,
.h-line span::after {
  content: "";
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  width: 50px;
  height: 2px;
  background-color: #383b67;
}

.h-line span::before {
  right: 100%;
  margin-right: 10px;
}

.h-line span::after {
  left: 100%;
  margin-left: 10px;
}

.h-line span {
  display: inline-block;
  position: relative;
}

.btnkat {
  text-transform: uppercase !important;
}

.form2 {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  transition: 0.3s;
}

.MuiAlert-filledSuccess {
  color: #fff !important;
  font-weight: 500 !important;
  background-color: #ff5b5b !important;
}

.accordion-non {
  /* height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  transition: 0.2s; */
  display: none;
}

.info {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #707070;
  text-decoration: underline;
  text-transform: uppercase;
}

.infohome {
  color: #383b67;
  text-decoration: none;
}

.design {
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.2);
  line-height: 80px;
  border-radius: 15px;
  position: relative;
  background-size: cover;
  margin: 0 auto;
}

.design p {
  font-size: 11px;
  margin: 0px;
  font-family: "Roboto-Bold";
  font-weight: 1000;
  letter-spacing: 0px;
}

.designcolor {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid;
  bottom: 0;
  right: 0;
  margin-bottom: -7px;
  margin-right: -7px;
}

.lockdesign{
  position: absolute;
  right:0;
  top:0;
  margin-top:-10px;
  margin-right: -7px;
}

.for {
  color: #383b67;
  font-size: 10px;
  font-family: "Roboto-Bold";
}

.item-carousel {
  padding: 30px;
}

.right {
  text-align: right;
}

.carouselimg {
  border-radius: 12px;
  box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.2);
  width: 90%;
  margin-bottom: 30px;
}

.carouselimghome {
  /* width: 85%; */
  margin-bottom:15px
}

.forcefull {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.username {
  font-size: 12px;
  font-family: "Roboto-Bold";
  margin-top: 0px;
}

.contentimg {
  width: 100%;
  margin-bottom: 0px;
}

.title {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Roboto-Bold";
}

.accordiontitle {
  width: 100%;
  position: relative;
}

.accordiontitle > * {
  display: inline-block;
}

.accordiontitle span {
  position: absolute;
  top: 9px;
  right: 0px;
}

.tutup {
  transform: rotate(-180deg);
}

.swal2-popup .swal2-styled.swal2-confirm{
  background-color: #383b67!important;
  border-radius: 15px!important;
}

.swal2-popup .swal2-styled.swal2-cancel{
  border-radius: 15px!important;
}

.swal2-title p{
  margin-bottom: 0px;
  margin-top: 0px;
}

.hash{
  color:#686868;
  text-transform: lowercase;
}

.typekategori{
  position: absolute;
  right:20px;
  color:#686868;
}

.jcl{
  justify-content: left!important;
  padding-left: 20px!important;
}

.designlain{
  width: 100%;
}

.designlain > *{
  display: inline-block;
}

.over{
  overflow: hidden;
  width: 1366px;
}

.hlogin{
  text-align: right;
  padding:0 40px;
  line-height: 140px;
}

.hblog{
  text-align: left;
  padding:0 40px;
  line-height: 140px;
}

.linklink{
  position: relative;  
}

.zz1{
  min-height: 200px;
}

.zz2, .zz3{
  min-height: 430px;
}

.linklink img{
  position: absolute;
}

.mid{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%)!important;
	-o-transform: translateY(-50%)!important;
  transform: translateY(-50%)!important; 
  text-align: center; 
  align-items: center;
}

.home p{
  font-size: 20px;
}

@-webkit-keyframes bounce {
  0% { transform: translateY(-5px)  }
  50% { transform: translateY(10px) }
  100% { transform: translateY(-5px) }
}

@keyframes bounce {
  0% { transform: translateY(-5px)  }
  50% { transform: translateY(10px) }
  100% { transform: translateY(-5px) }
}


@-webkit-keyframes zoom {
  0% { transform: scale(1) rotate(0deg)  }
  50% { transform: scale(0.98) rotate(0.1deg) }
  100% { transform: scale(1) rotate(0deg) }
}

@keyframes zoom {
  0% { transform: scale(1) rotate(0deg)  }
  50% { transform: scale(0.98) rotate(0.1deg) }
  100% { transform: scale(1) rotate(0deg) }
}

.bounce {
  -webkit-animation: bounce 1s infinite ease-in-out;
  -o-animation: bounce 1s infinite ease-in-out;
  -ms-animation: bounce 1s infinite ease-in-out; 
  -moz-animation: bounce 1s infinite ease-in-out; 
  animation: bounce 1s infinite ease-in-out;
}

.zoom:hover {
  -webkit-animation: zoom 2s infinite ease-in-out;
  -o-animation: zoom 2s infinite ease-in-out;
  -ms-animation: zoom 2s infinite ease-in-out; 
  -moz-animation: zoom 2s infinite ease-in-out; 
  animation: zoom 2s infinite ease-in-out;
  cursor: pointer;
}

.rectangle1{
  margin-top: 280px;
}

.MuiTypography-body1{
  font-size: 12px!important;
  color: rgba(0,0,0,0.67)!important
}

.bl{
  display: none;
}

.copyright{
  font-size:14px;
  margin-bottom: 30px;
}

.title-class p{
  font-size:24px
}

.content-class{
  font-size: 15px!important;
}

.confirm-button-class{
  font-size: 14px!important;
}

.cancel-button-class{
  font-size: 14px!important;
}

.container-class{
  padding:30px!important;
  z-index: 1400!important;
}

.popup-class{
  border-radius: 30px!important;
}

.swal2-actions{
  margin-top:15px!important
}

.homedialog .MuiPaper-root{
  background-color: white!important;
  color:black!important
}

.btn-rounded{
  width:90px;
  height:90px;
  border-radius: 100%!important;
}

.m{
  display: none;
}

@media only screen and (max-width: 1199px) {
  .rectangle1{
    margin-top:200px
  }

  .linklink img{
    width: 100%;
  }

  .hlogin{
    padding-right: 10px;
  }
  
  .hblog{
    padding-left: 20px;
  }

  .zz1{
    min-height: 192px;
  }

  .zz2{
    min-height: 406px;
  }

  .zz3{
    min-height: 336px;
  }
}

@media only screen and (max-width: 991px) {
  .rectangle1{
    margin-top:180px!important
  }

  .zz1{
    min-height: 140px;
  }

  .zz2{
    min-height: 298px;
  }

  .zz3{
    min-height: 246px
  }

  .dividerver{
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  body {
    background-size: cover;
  }

  .hlogin{
    display: none;
  }
  
  .hblog{
    display: none;
  }

  .bl{
    display: block;
    line-height: normal;
  }

  .nm{
    display: none;
  }

  .des{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }
  
  .logohome{
    width: 100%;
  }

  .rectangle1{
    margin-top:0px!important;
    margin-bottom: 15px;
  }

  .d{
    display: none;
  }

  .m{
    display: block;
  }

  .centermob{
    text-align: center;    
  }

  .centermob img{
    width: 100%;
  }

  .spacer-15-mob{
    height: 15px;
  }
  
  .mid{
    position: relative;
    top: 0%;
    -webkit-transform: translateY(0%)!important;
    -o-transform: translateY(0%)!important;
    transform: translateY(0%)!important;
    margin-bottom: 30px;
  }

  .mid p{
    margin-top: 0px;
  } 

}

@media only screen and (min-width: 768px) {
  body{
    background-image: none!important;
  }

  .previewmob{
    width: 300px;
    height: 600px;    
    margin:0 auto;
    border: 15px solid #383B67;
    border-radius: 30px;
    overflow: scroll; 
    position: sticky;
    top: 2%;
  }

  .previewmob::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .previewmob {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .wrapperPreview{
    border-radius: 20px;
    padding-bottom: 20px;
    background-attachment: scroll!important;
  }

  .dividerver{
    border-left: 2px solid #383B67;
  }
}

.introjs-helperNumberLayer{
  display: none!important;
}

.BrainhubCarouselItem{
  display: block!important;
}

.shop{
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 99999999;
}