@font-face {
	font-family: Roboto-Regular;
	src: url("/font/Roboto/Roboto-Regular.ttf");
}

@font-face {
	font-family: Roboto-Medium;
	src: url("/font/Roboto/Roboto-Medium.ttf");
}

@font-face {
	font-family: Roboto-Bold;
	src: url("/font/Roboto/Roboto-Bold.ttf");
}

@font-face {
	font-family: segoeui;
	src: url("/font//segoeui.TTF");
}

body {
  margin: 0;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}